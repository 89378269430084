@import "../../shared/constants.scss";

.order-page{

  .header{
    color: $primary;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 5px;
  }


    .order-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 3vh;
        padding: 10px;
        
    .order-inner { 
        width: 100%;
        max-width: 800px;
    }

    .flex-task {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        gap: 20px;

    .assignment-task {
        display: flex;
        flex: 1;
        justify-content: flex-end;

        .assignment-info {
            display: flex;
            flex: 1;
            flex-direction: column;
            min-width: 350px;

            .info-label {
              color: rgb(32, 32, 32);
              font-size: 1em;
              margin-top: 5px;
              font-weight: bold;
              margin-bottom: 10px;
            }

            .assignment-card {
              box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
              margin-bottom: 15px;
              min-width: 350px;
              background: linear-gradient(90deg, rgba(250,250,250,1) 0%, rgba(245,245,245,1) 33%, rgba(250,250,250,1) 100%);
        
              .content {
                margin: 7.5px;
                font-size: 0.9em;
              }
            }

        }
    }
      
    .order-task {
        display: flex;
        flex: 1;
    
        .order-info {
             min-width: 350px;
             flex: 1;
            .info-item {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 10px;
            }

            .info-label {
              color: rgb(32, 32, 32);
              font-size: 1em;
              margin-top: 5px;
              font-weight: bold;
            }
            .info-content {
              flex: 1;
              font-size: 1.1em;
              display: flex;
              flex-direction: row;
              margin-top: 3px;

              a {
                text-decoration: none;
                color: blue;
              }
            
              a:hover {
                color: rgb(98, 98, 255);
                text-decoration: underline;
              }
            }
            .info-item-edit {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding-bottom: 20px;
            }

            .info-label-edit {
              color: rgb(32, 32, 32);
              font-size: 1em;
              padding-bottom: 10px;
              font-weight: bold;
            }
            .info-content-edit {
              flex: 1;
              font-size: 1.1em;
              display: flex;
              flex-direction: row;
              margin-top: 3px;
              width: 100%;
            }
            .info-content-edit-small{
              flex: 1;
              font-size: 1.1em;
              display: flex;
              flex-direction: row;
              margin-top: 3px;
              width: 30%;
            }
          }


        }
      }
    }

    .order-header {
      margin: 7px;
    }

    .flex{
      display: flex;
    }

    .g10{
      gap: 10px;
    }
    .g5{
      gap: 5px;
    }

    .line-wrapper{
      display: flex;
      justify-content: space-between;

    .left {
        display: inline;
        text-align: left;
    }
    .right {
        display: inline;
        text-align: right;
    }
  }

  .important-deadline {
    font-size: 16px;
    font-weight: bold;
    color: red;
  }

  .timer-clicker { 
    display: flex; 
    justify-content: flex-start;
    .left-element, .right-element {
        border-color: #ccc;
    }
    .left-element {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0px;
    }
    .center-element {
        border-radius: 0px;
        text-align: justify;
        text-align-last: center;
    }
    .right-element {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0px;
    }
}
.container-orders-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .container-order-wrapper {
        display: flex;
        width: 100%;
        gap: 10px;
        flex-direction: row;
        align-items: flex-start;

        &:not(:first-child) {
            .field-title {
                display: none;
            }

            .delete-btn {
                margin-top: 5px;
            }
        }
    }

    .item {
        flex: 1;
    }

    .delete-btn {
        margin-top: 60px;
    }

    .add-btn {
        margin: 10px;
        margin-bottom: 20px;
    }

    @media only screen and (max-width: 720px) {
        .container-orders-wrapper {
        }

        .container-order-wrapper {
            flex-direction: column;
            gap: 2px;
            align-items: flex-end;

            .field-title.field-title {
                display: block;
            }
        }

        .delete-btn.delete-btn.delete-btn {
            margin-top: 20px;
        }

        .add-btn {
            margin: 20px;
            margin-bottom: 20px;
        }

        .item {
            flex: 1;
            align-self: stretch;
         
        }

    }

}
