@import '../../shared/constants.scss';

.align-icon { 
    display: flex; 
    align-items: center; 
    gap: 5px;

    &.small {
     svg {
         font-size: 12px;
     }
    }
 }
 
.UnderPlanningColor {
    color: $UnderPlanningColor;
}

.ApprovedColor {
  color: $ApprovedColor;
}

.StartedColor {
  color: $StartedColor;
}

.CompletedColor {
  color: $CompletedColor;
}

.CancelledColor {
  color: $CancelledColor;
}

.NotDeliveredColor {
  color: $NotDeliveredColor;
}

.DeletedColor {
    color: $DeletedColor;
}
 
