.content-wrapper {
    display: flex;
    justify-content: center;
    .content-inner {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1;
        max-width: 800px;
    }
}