.vehicle-clicker { 
    display: flex; 
    justify-content: flex-start;
    .left-element, .right-element {
        border-color: #ccc;
    }
    .left-element {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0px;
    }
    .center-element {
        border-radius: 0px;
        text-align: justify;
        text-align-last: center;
    }
    .right-element {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0px;
    }
}

.masse-inn-page {
    .same-line-wrapper {
        display: flex;
        .same-line-textbox {
            flex: 1;
        }
        .same-line-text {
            padding-left: 10px;
            line-height: 35px;
            vertical-align: middle;
        }
     }
}

.masse-ut-page {
    .same-line-wrapper {
        display: flex;
        .same-line-textbox {
            flex: 1;
        }
        .same-line-text {
            padding-left: 10px;
            line-height: 35px;
            vertical-align: middle;
        }
     }
}

.tilleggsdetaljer-page {
    .fill-screen {
        flex: 1;
    }

    .flex-parent {
        display: flex;
    }

    .timer-clicker { 
        display: flex; 
        justify-content: flex-start;
        .left-element, .right-element {
            border-color: #ccc;
        }
        .left-element {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0px;
        }
        .center-element {
            border-radius: 0px;
            text-align: justify;
            text-align-last: center;
        }
        .right-element {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0px;
        }
    }

    .mini-padding { 
        padding: 2px;
    }
}

.container-order-page {
    padding-bottom: 80px;


    .orders-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 10px;
    }

    .order-wrapper {
        display: flex;
        width: 100%;
        gap: 10px;
        flex-direction: row;
        align-items: flex-start;

        &:not(:first-child) {
            .field-title {
                display: none;
            }

            .delete-btn {
                margin-top: 5px;
            }
        }
    }

    .item {
        flex: 1;
    }

    .delete-btn {
        margin-top: 60px;
    }

    .add-btn {
        margin: 10px;
    }

    @media only screen and (max-width: 720px) {
        .orders-wrapper {
            margin: 20px;
        }

        .order-wrapper {
            flex-direction: column;
            gap: 2px;
            align-items: flex-end;

            .field-title.field-title {
                display: block;
            }
        }

        .delete-btn.delete-btn.delete-btn {
            margin-top: 20px;
        }

        .add-btn {
            margin: 20px;
        }

        .item {
            flex: 1;
            align-self: stretch;
         
        }

    }
}