.avoid-height-shift {
    max-height: 40px;
}

.option {
    display: flex;

.label {
    flex: 1;
}

.last-used {
    font-style: italic;
    font-size: 12px;

    @media screen and (max-width: 600px) {
      display: none
    }
  }
}