// defaults / theme
$primary: #004b8b;
$primaryDark: #142a42;
$secondary: #ffffff;
$warningColor: #ff8000;
$headerHeight: 24px;
$mobileHeaderHeight: 16px;
$pagewidth: 800px;

// event colors
$UnderPlanningColor: #ff8000;
$ApprovedColor: #0066cc;
$StartedColor: #009900;
$CompletedColor: #4d4d4d;
$CancelledColor: #cccccc;
$NotDeliveredColor: #ff0000;
$DeletedColor: black;
$ExpProjectBorderColor: #990000;

// Any changes here should also be applied to src/core/constants.ts
