// This file is for global styles
// import this to src/index.tsx

// disable event left-drag
.not-resizable-start .fc-event-resizer-start {
    display: none !important;
    cursor: auto !important;
  }
  .not-resizable-end .fc-event-resizer-end {
    display: none !important;
    cursor: auto !important;
  }
  