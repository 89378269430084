.edit-task-component {
    .MuiInput-input.MuiInputBase-input.Mui-disabled {
        color: #555;
        -webkit-text-fill-color: #555;
    }
    .original-order-card {
        box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
        background: linear-gradient(
            90deg,
            rgba(250, 250, 250, 1) 0%,
            rgba(246, 246, 246, 1) 33%,
            rgba(250, 250, 250, 1) 100%
        );
        margin-bottom: 16px;
    }
    .overlapMessage {
        display: flex;
        flex-direction: column;
        gap: 5px;
        color: red;

        .title {
            font-size: 18px;
        }
        .event {
            font-size: 16px;
            font-style: italic;
        }
    }
}
